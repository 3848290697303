/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from 'react';

import { ArrowDownCircleIcon, ArrowUpCircleIcon, PlusIcon } from '@heroicons/react/24/outline';
import debounce from 'lodash.debounce';
import { twMerge } from 'tailwind-merge';

import {
  GET_ALL_ESHOP_CATEGORIES,
  OperationsPagination,
  SHOW_ESHOP_V2,
  TableDeleteButton,
  TableEditButton,
} from '@mwell-healthhub/commons';

import DeleteCategoryModal from './DeleteCategoryModal';
import { Routes } from '../../enums';
import {
  useGetAllEshopCategories,
  useQueryParamState,
  useRouter,
  useToggle,
  useUpdateEshopCategoryBatchMutation,
} from '../../hooks';
import { Category } from '../../types';
import { sortByKey } from '../../utils';
import { searchValidator } from '../../validators';
import Button from '../Button';
import SearchInput from '../SearchInput';
import Table, { TableColumn } from '../Table';
import clsx from 'clsx';
import toast from '../toast';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
  isLoadingUsers?: boolean;
};

function CategoriesList(props: Props) {
  const router = useRouter();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValidationError, setSearchValidationError] = useState<string>('');
  const [search, setSearch] = useQueryParamState('search');
  const [selectedRow, setSelectedRow] = useState<Category>();
  const queryClient = useQueryClient();

  const { isOpen: isDeleteModalOpen, handleToggle: toggleDeleteModal } = useToggle(false);

  const queryParams = {
    ...(search && { query: search }),
    limit: 10,
    page: currentPage,
  };
  const { data: paginatedCategories, isFetching: isGetAllCategoriesLoading } =
    useGetAllEshopCategories(queryParams);
  const { mutate: updateCategoriesBatch, isLoading: isUpdatingCategoryOrder } =
    useUpdateEshopCategoryBatchMutation({
      onSuccess: () => {
        toast({ message: 'Order of Categories updated', type: 'success' });
      },
      onError: () => {
        toast({ message: 'Failed to update categories order', type: 'error' });
        queryClient.invalidateQueries({ queryKey: [GET_ALL_ESHOP_CATEGORIES] });
      },
      onSettled: () => {
        setHasOrderMoved(false);
      },
    });

  const { items: categories = [], totalPages, itemsPerPage } = paginatedCategories ?? {};
  const [mutableCategories, setMutableCategories] = useState<Category[]>(categories);
  const [hasOrderMoved, setHasOrderMoved] = useState<boolean>(false);

  const sortedCategories = useMemo(() => {
    return categories.length ? sortByKey(categories, 'name') : [];
  }, [categories]);

  const handleActionEdit = (rowData: Category) => () => {
    router.push(`${Routes.CATEGORIES}/${rowData.id}/edit`);
  };

  const handleAddCategory = () => {
    router.push(`${Routes.CATEGORIES}/add`);
  };

  const handleDeleteCategory = (category: Category) => () => {
    setSelectedRow(category);
    toggleDeleteModal();
  };

  const handleCloseDeleteUserModal = () => {
    setSelectedRow(undefined);
    toggleDeleteModal();
  };

  const handleOrderChange = (index: number, direction: 'up' | 'down') => {
    const newIndex = direction === 'up' ? index - 1 : index + 1;
    const categoriesOrder = mutableCategories.map((cat, currentIndex) => ({
      ...cat,
      order: currentIndex,
    }));
    categoriesOrder[index].order = newIndex;
    categoriesOrder[newIndex].order = index;
    categoriesOrder.sort((a, b) => a.order - b.order);
    setMutableCategories(categoriesOrder);
    setHasOrderMoved(true);
  };

  const updateCategoryOrder = () => {
    updateCategoriesBatch(mutableCategories);
  };

  const onCancelOrderChange = () => {
    setMutableCategories(categories);
    setHasOrderMoved(false);
  };

  useEffect(() => {
    if (categories.length) {
      setMutableCategories(categories);
    } else if (categories.length !== mutableCategories.length) {
      setMutableCategories([]);
    }
  }, [categories]);

  const columns: TableColumn<Category>[] = useMemo(
    () => [
      {
        header: 'Order',
        key: 'order',
        headerClassName: !hasOrderMoved || search || !SHOW_ESHOP_V2 ? 'hidden' : '',
        className: !hasOrderMoved || search || !SHOW_ESHOP_V2 ? 'hidden' : 'w-12',
        render: (_data, index) => {
          const isFirst = index === 0;
          const isLast = index === mutableCategories.length - 1;

          if (!SHOW_ESHOP_V2) {
            return <div></div>;
          }

          return (
            <div className="flex">
              {!isFirst && (
                <ArrowUpCircleIcon
                  className="h-5 w-5 text-gray-500"
                  onClick={() => handleOrderChange(index, 'up')}
                />
              )}
              {!isLast && (
                <ArrowDownCircleIcon
                  className={clsx('h-5 w-5 text-gray-500', isFirst && 'ml-5')}
                  onClick={() => handleOrderChange(index, 'down')}
                />
              )}
            </div>
          );
        },
      },
      {
        header: 'Name',
        key: 'name',
        render: (data) => <div className="text-sm font-semibold text-gray-600">{data.name}</div>,
      },
      {
        header: 'Action',
        key: 'action',
        className: 'w-[60px]',
        render: (data) => (
          <div className="flex flex-row gap-4">
            <TableEditButton onClick={handleActionEdit(data)} />
            <TableDeleteButton className="text-red-500" onClick={handleDeleteCategory(data)} />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutableCategories, hasOrderMoved],
  );

  const debouncedSetSearch = debounce(async (value) => {
    try {
      setSearchValidationError('');
      await searchValidator.validate(value.trim());
      setSearch(value.trim());
    } catch (error: any) {
      setSearchValidationError(error?.message);
    }
  }, 500);

  return (
    <div>
      <div className="mb-6 mt-4 flex flex-row justify-between sm:flex sm:items-center">
        <div>
          <h1 className="text-4 text-2xl font-semibold">Product Categories</h1>
        </div>
        <Button
          variant="primary"
          className="flex gap-2 px-[14px] py-[10px] font-normal"
          onClick={handleAddCategory}
        >
          <PlusIcon height={18} width={18} className="mr-2" /> Add Category
        </Button>
      </div>
      <div className="flex flex-col gap-4">
        <div
          className={twMerge(
            'items-end gap-4 space-y-2 lg:flex lg:space-y-0',
            searchValidationError ? 'relative items-start' : 'items-end',
          )}
        >
          <SearchInput
            className="w-full"
            defaultValue={search}
            placeholder="Search name"
            onChange={debouncedSetSearch}
            iconVariant="plain"
            errorMessage={searchValidationError}
          />
        </div>
        <div className="flex flex-col rounded-lg bg-white">
          <Table
            columns={columns}
            data={mutableCategories}
            isLoading={isGetAllCategoriesLoading}
            noDataText="No Categories found."
          />
          {!hasOrderMoved && !search && SHOW_ESHOP_V2 && (
            <div className="flex-1 pt-3">
              <Button
                variant="primary"
                className="min-w-[136px] font-medium"
                onClick={() => {
                  setHasOrderMoved(!hasOrderMoved);
                }}
              >
                Edit Order
              </Button>
            </div>
          )}
          {hasOrderMoved && !search && SHOW_ESHOP_V2 && (
            <div className="flex flex-1 justify-end gap-2 pt-3">
              <Button
                variant="tertiary"
                className="min-w-[136px] font-medium"
                onClick={onCancelOrderChange}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="px-[14px] py-[10px] font-normal"
                onClick={updateCategoryOrder}
                isLoading={isUpdatingCategoryOrder}
              >
                Save
              </Button>
            </div>
          )}
        </div>
      </div>
      <OperationsPagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        totalItems={sortedCategories.length}
        itemsCount={sortedCategories.length}
      />
      {selectedRow && (
        <DeleteCategoryModal
          isOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteUserModal}
          category={selectedRow}
        />
      )}
    </div>
  );
}

export default CategoriesList;
